import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'payment-funds',
    name: 'payment-funds',
    component: () => import('../pages/PaymentFundsView.vue'),
    meta: {
      pageTitle: 'Pembayaran Dana'
    }
  },
  {
    path: 'payment-funds/auction-aplicant',
    name: 'payment-funds/auction-aplicant',
    component: () => import('../pages/PaymentFundsView.vue'),
    meta: {
      pageTitle: 'Pembayaran Dana'
    }
  },
  {
    path: 'payment-funds/auction-admin-fees',
    name: 'payment-funds/auction-admin-fees',
    component: () => import('../pages/PaymentFundsView.vue'),
    meta: {
      pageTitle: 'Pembayaran Dana'
    }
  }
]

const secondRoutesPaymentFund: RouteRecordRaw[] = [
  {
    path: 'payment-funds/:id/detail',
    name: 'payment-funds/detail',
    component: () => import('../pages/PaymentFundsDetailView.vue')
  },
  {
    path: 'payment-funds/:id/:index/detail',
    name: 'payment-funds/:id/detail',
    component: () => import('../pages/PaymentFundsItemDetailView.vue')
  },
  {
    path: 'payment-funds/auction-aplicant/:id/detail',
    name: 'payment-funds/auction-aplicant/detail',
    component: () => import('../pages/PaymentApplicantDetailView.vue')
  },
  {
    path: 'payment-funds/auction-aplicant/:id/:index/detail',
    name: 'payment-funds/auction-aplicant/:id/detail',
    component: () => import('../pages/PaymentApplicantItemDetailView.vue')
  },
  {
    path: 'payment-funds/auction-admin-fees/:id/detail',
    name: 'payment-funds/auction-admin-fees/detail',
    component: () => import('../pages/AcceptAuctionAdminFeeDetail.vue')
  },
  {
    path: 'payment-funds/auction-admin-fees/:id/:index/detail',
    name: 'payment-funds/auction-admin-fees/:id/detail',
    component: () => import('../pages/AcceptAuctionAdminItemDetailView.vue')
  }
]
export { secondRoutesPaymentFund }
export default routes
