import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'auction/ongoing',
    name: 'auction/ongoing',
    component: () => import('../pages/AuctionView.vue'),
    meta: {
      pageTitle: 'Lelang'
    }
  },
  {
    path: 'auction/done',
    name: 'auction/done',
    component: () => import('../pages/AuctionView.vue'),
    meta: {
      pageTitle: 'Lelang'
    }
  }
]

const secondRoutesAuction: RouteRecordRaw[] = [
  {
    path: 'auction/ongoing/:id/detail',
    name: 'auction/ongoing/detail',
    component: () => import('../pages/AuctionDetailView.vue')
  },
  {
    path: 'auction/ongoing/:id/:index/detail',
    name: 'auction/ongoing/:id/detail',
    component: () => import('../pages/AuctionItemDetailView.vue')
  },
  {
    path: 'auction/done/:id/detail',
    name: 'auction/done/detail',
    component: () => import('../pages/AuctionDetailDoneView.vue')
  },
  {
    path: 'auction/done/:id/:index/detail',
    name: 'auction/done/:id/detail',
    component: () => import('../pages/AuctionItemDetailView.vue')
  }
]

export { secondRoutesAuction }
export default routes
