import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'refunds',
    name: 'refunds',
    component: () => import('../pages/RefundsView.vue'),
    meta: {
      pageTitle: 'Pengembalian Dana'
    }
  }
]

const secondRoutesRefund: RouteRecordRaw[] = [
  {
    path: 'refunds/:id/detail',
    name: 'refunds/detail',
    component: () => import('../pages/RefundDetailView.vue')
  }
]

export { secondRoutesRefund }
export default routes
