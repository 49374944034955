<script setup lang="ts">
import { ref, onMounted, reactive } from 'vue'
import { LoginIcon, LogoutIcon } from '@heroicons/vue/outline'
import { RouterLink } from 'vue-router'
import VueFeather from 'vue-feather'
import useSidebar from '@/composeables/use-sidebar'
import {
  AuctionIcon,
  DollarCurrencyIcon,
  RefundIcon,
  DashboardIcon,
  ProductsIcon,
  FilesIcon,
  UserIcon,
  UsersIcon,
  EmployeeIcon
} from '@/components/icons'

// logo / icon
// import logoBidcloud from '@/assets/logo/logo_bidcloud.png'
// import iconBidcloud from '@/assets/logo/logo_bidcloud.svg'

// declare variables
const { isSidebarFolded } = useSidebar()
const isMounted = ref(false)
const listDashboardMenu = reactive([
  {
    title: 'Dasbor',
    icon: 'dashboard',
    routeName: 'dashboard',
    roles: [],
    current: false
  },
  {
    title: 'Laporan',
    icon: 'file',
    routeName: 'report',
    roles: [],
    current: false
  }
])
const listSettingMenu = reactive([
  {
    title: 'Profil',
    icon: 'user',
    routeName: 'profile',
    roles: [],
    current: false
  },
  {
    title: 'Pegawai',
    icon: 'employee',
    routeName: 'employee',
    roles: [],
    current: false
  }
])
const listManageMenu = reactive([
  {
    title: 'Pengguna',
    icon: 'users',
    routeName: 'users',
    path: 'officers',
    roles: [],
    current: false
  },
  {
    title: 'Objek Lelang',
    icon: 'layers',
    routeName: 'object-auction',
    path: 'object-auction/lot',
    roles: [],
    current: false
  }
])

const listTransactionMenu = reactive([
  {
    title: 'Lelang',
    icon: 'auction',
    routeName: 'auction/ongoing',
    path: 'auction/done',
    roles: [],
    current: false
  },
  {
    title: 'Pembayaran Dana',
    icon: 'dollar',
    routeName: 'payment-funds',
    path: 'payment-funds/auction-aplicant',
    pathFee: 'payment-funds/auction-admin-fees',
    roles: [],
    current: false
  },
  {
    title: 'Pengembalian Dana',
    icon: 'refund',
    routeName: 'refunds',
    roles: [],
    current: false
  }
])

// components vue
onMounted(() => {
  isMounted.value = true
})

// functions
</script>

<template>
  <aside
    :class="isSidebarFolded ? 'w-24' : 'w-60'"
    class="fixed left-0 top-0 z-40 h-full min-h-screen overflow-y-auto overflow-x-hidden bg-bs-white-1 transition-all duration-100"
  >
    <Teleport v-if="isMounted" to="#sidemenuButtonTeleport">
      <button
        id="sidemenuButton"
        type="button"
        class="rounded-md bg-white p-2 hover:bg-gray-100"
        @click="() => (isSidebarFolded = !isSidebarFolded)"
      >
        <LogoutIcon v-if="isSidebarFolded" class="h-6 w-6 text-bs-black-2" />
        <LoginIcon v-else class="h-6 w-6 text-bs-black-2" />
      </button>
    </Teleport>

    <!--
      <RouterLink to="" class="contents">
      <img
      v-if="!isSidebarFolded"
      :src="logoBidcloud"
      alt="logo"
      width="150"
      class="mx-auto mt-4 block"
      />
      <img
      v-else
      :src="iconBidcloud"
      alt="logo"
      width="50"
      class="mx-auto mt-4 block"
      />
      </RouterLink>
    -->

    <div class="px-2" :class="isSidebarFolded ? 'mt-[100px]' : 'mt-[100px]'">
      <section>
        <div class="border-b border-bs-line pb-4">
          <p
            class="mb-2 text-sm font-semibold text-bs-red-primary-1 transition-all duration-100"
            :class="isSidebarFolded ? 'p-0 text-left' : 'px-3 text-left'"
          >
            Beranda
          </p>

          <div
            v-for="(homepage, indexHomepage) in listDashboardMenu"
            :key="indexHomepage"
          >
            <RouterLink
              :to="{ name: homepage.routeName }"
              class="flex items-center gap-4 rounded p-3 transition-all duration-100 hover:bg-bs-red-2"
              :class="
                homepage.routeName === $route.name
                  ? 'border-l-4 border-bs-red-primary-1 font-semibold text-bs-red-primary-1'
                  : 'border-l-4 border-transparent font-medium text-bs-black-2'
              "
            >
              <DashboardIcon
                v-if="homepage.icon === 'dashboard'"
                :class="
                  homepage.routeName === $route.name
                    ? 'fill-bs-red-primary-1'
                    : 'fill-bs-black-7'
                "
              />
              <FilesIcon
                v-else
                :class="
                  homepage.routeName === $route.name
                    ? 'fill-bs-red-primary-1'
                    : 'fill-bs-black-7'
                "
              />
              <span
                v-if="isSidebarFolded === false"
                class="text-sm"
                :class="
                  homepage.routeName === $route.name
                    ? 'text-bs-red-primary-1'
                    : 'text-black'
                "
                >{{ homepage.title }}</span
              >
            </RouterLink>
          </div>
        </div>
        <div class="mt-3 border-b border-bs-line pb-4">
          <p
            class="mb-2 text-sm font-semibold text-bs-red-primary-1 transition-all duration-100"
            :class="isSidebarFolded ? 'p-0 text-left' : 'px-3 text-left'"
          >
            Kelola
          </p>
          <div
            v-for="(homepage, indexHomepage) in listManageMenu"
            :key="indexHomepage"
          >
            <RouterLink
              :to="{ name: homepage.routeName }"
              class="flex items-center gap-4 rounded p-3 transition-all duration-100 hover:bg-bs-red-2"
              :class="
                homepage.routeName === $route.name ||
                homepage.path === $route.name
                  ? 'border-l-4 border-bs-red-primary-1 font-semibold text-bs-red-primary-1'
                  : 'border-l-4 border-transparent font-medium text-bs-black-2'
              "
            >
              <UsersIcon
                v-if="homepage.icon === 'users'"
                :class="
                  homepage.routeName === $route.name ||
                  homepage.path === $route.name
                    ? 'fill-bs-red-primary-1'
                    : 'fill-bs-black-7'
                "
              />
              <ProductsIcon
                v-else
                :class="
                  homepage.routeName === $route.name ||
                  homepage.path === $route.name
                    ? 'fill-bs-red-primary-1'
                    : 'fill-bs-black-7'
                "
              />
              <span
                v-if="isSidebarFolded === false"
                class="text-sm"
                :class="
                  homepage.routeName === $route.name ||
                  homepage.path === $route.name
                    ? 'text-bs-red-primary-1'
                    : 'text-black'
                "
                >{{ homepage.title }}</span
              >
            </RouterLink>
          </div>
        </div>
        <div class="mt-3 border-b border-bs-line pb-4">
          <p
            class="mb-2 text-sm font-semibold text-bs-red-primary-1 transition-all duration-100"
            :class="isSidebarFolded ? 'p-0 text-left' : 'px-3 text-left'"
          >
            Transaksi
          </p>
          <div
            v-for="(homepage, indexHomepage) in listTransactionMenu"
            :key="indexHomepage"
          >
            <RouterLink
              :to="{ name: homepage.routeName }"
              class="flex items-center gap-4 rounded p-3 transition-all duration-100 hover:bg-bs-red-2"
              :class="
                homepage.routeName === $route.name ||
                homepage.path === $route.name ||
                homepage.pathFee === $route.name
                  ? 'border-l-4 border-bs-red-primary-1 font-semibold text-bs-red-primary-1'
                  : 'border-l-4 border-transparent font-medium'
              "
            >
              <AuctionIcon
                v-if="homepage.icon === 'auction'"
                :class="
                  homepage.routeName === $route.name ||
                  homepage.path === $route.name
                    ? 'fill-bs-red-primary-1'
                    : 'fill-bs-black-7'
                "
              />
              <DollarCurrencyIcon
                v-else-if="homepage.icon === 'dollar'"
                :class="
                  homepage.routeName === $route.name ||
                  homepage.path === $route.name ||
                  homepage.pathFee === $route.name
                    ? 'fill-bs-red-primary-1'
                    : 'fill-bs-black-7'
                "
              />
              <RefundIcon
                v-else
                :class="
                  homepage.routeName === $route.name
                    ? 'fill-bs-red-primary-1'
                    : 'fill-bs-black-7'
                "
              />
              <span
                v-if="isSidebarFolded === false"
                class="text-sm"
                :class="
                  homepage.routeName === $route.name ||
                  homepage.path === $route.name ||
                  homepage.pathFee === $route.name
                    ? 'text-bs-red-primary-1'
                    : 'text-bs-black-3'
                "
                >{{ homepage.title }}</span
              >
            </RouterLink>
          </div>
        </div>
        <div class="mt-3 border-b border-bs-line pb-4">
          <p
            class="mb-2 text-sm font-semibold text-bs-red-primary-1 transition-all duration-100"
            :class="isSidebarFolded ? 'p-0 text-left' : 'px-3 text-left'"
          >
            Pengaturan
          </p>
          <div
            v-for="(setting, indexHomepage) in listSettingMenu"
            :key="indexHomepage"
          >
            <RouterLink
              :to="{ name: setting.routeName }"
              class="flex items-center gap-4 rounded p-3 transition-all duration-100 hover:bg-bs-red-2"
              :class="
                setting.routeName === $route.name
                  ? 'border-l-4 border-bs-red-primary-1 font-semibold text-bs-red-primary-1'
                  : 'border-l-4 border-transparent font-medium text-bs-black-2'
              "
            >
              <UserIcon
                v-if="setting.icon === 'user'"
                :class="
                  setting.routeName === $route.name
                    ? 'fill-bs-red-primary-1'
                    : 'fill-bs-black-7'
                "
              />
              <EmployeeIcon
                v-else
                :class="
                  setting.routeName === $route.name
                    ? 'fill-bs-red-primary-1'
                    : 'fill-bs-black-7'
                "
              />
              <span
                v-if="isSidebarFolded === false"
                class="text-sm"
                :class="
                  setting.routeName === $route.name
                    ? 'text-bs-red-primary-1'
                    : 'text-black'
                "
                >{{ setting.title }}</span
              >
            </RouterLink>
          </div>
        </div>
      </section>
    </div>
  </aside>
</template>
