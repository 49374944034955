import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'profile',
    name: 'profile',
    component: () => import('../pages/ProfileView.vue'),
    meta: {
      pageTitle: 'Profile'
    }
  }
]

export default routes
