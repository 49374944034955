import { $http } from '.'
import type { LoginRequest } from '@/types/auth'

function submitLogin(request: LoginRequest) {
  return $http.post('/v1/login', request)
}

function getProfile() {
  return $http.get('/v1/me')
}

function submitLogout() {
  return $http.post('/v1/logout')
}

export { submitLogin, getProfile, submitLogout }
