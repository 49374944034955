import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { defineRule, configure } from 'vee-validate'
import * as AllRules from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import id from '@vee-validate/i18n/dist/locale/id.json'
import Maska from 'maska'

import 'nprogress/nprogress.css'
import './index.css'

import VueFeather from 'vue-feather'
import App from './App.vue'
import router from './router'
import fieldNames from '@/locale/field-names-id.json'

configure({
  generateMessage: localize({
    id: {
      messages: id.messages,
      names: fieldNames
    }
  })
})

setLocale('id')

type ValidationRule = (value: unknown, params?: any) => boolean | string

Object.keys(AllRules).forEach((rule) => {
  const ruleName = rule as keyof typeof AllRules

  // Assert that the rule is a function of type ValidationRule
  // eslint-disable-next-line import/namespace
  const validationRule = AllRules[ruleName] as ValidationRule
  // eslint-disable-next-line import/namespace
  if (typeof validationRule === 'function') {
    defineRule(rule, validationRule)
  }
})

const app = createApp(App)
app.component(VueFeather.name as string, VueFeather)

app.use(Maska)
app.use(createPinia())
app.use(router)
app.mount('#app')
