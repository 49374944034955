import { reactive, toRefs } from 'vue'

// global state
const state = reactive({
  isSidebarFolded: false
})

export default function useSidebar() {
  // function changeSidebar(stateName: string | number, stateValue: any) {
  //   state[stateName] = stateValue
  // }

  return {
    ...toRefs(state) // convert to refs when returning
    // changeSidebar
  }
}
