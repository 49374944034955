<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="120 120 290 290">
    <g data-name="Currency Refund">
      <path
        d="M179.377 276.148a74.949 74.949 0 1 0 74.948-74.949 74.951 74.951 0 0 0-74.948 74.949zm109.475-26.277.749 1.525-15.439 7.14-.764-1.274c-2.72-4.499-10.716-8.02-18.204-8.02-5.051 0-16.798.901-16.798 9.247 0 6.008 7.564 7.39 17.459 8.568 15.418 1.899 36.528 4.502 36.528 27.196 0 16.714-13.572 25.509-27.514 27.055v11.754h-18.068v-11.189c-14.65-1.722-25.416-9.027-30.407-20.698l-.655-1.536 16.774-7.102.645 1.532c2.684 6.371 11.954 11.172 21.566 11.172 4.327 0 18.431-.791 18.431-10.992 0-5.347-6.028-8.545-18.965-10.075-14.481-1.619-34.328-3.837-34.328-25.689 0-13.406 10.038-22.772 26.94-25.25v-10.31h18.067v10.35c7.837 1.377 18.198 4.781 23.983 16.596z"
      />
      <path
        d="m339.908 168.096-12.25 15.743a117.017 117.017 0 1 1-63.194-24.179l-23.654 24.815 15.933 15.198 33.898-35.591.007.01 15.189-15.947-15.917-15.185-.013.004-35.578-33.909-15.195 15.947 25.918 24.688a136.734 136.734 0 0 0-144.155 115.195c-11.708 74.486 39.364 144.637 113.855 156.37a138.715 138.715 0 0 0 21.539 1.69 136.817 136.817 0 0 0 83.617-244.85z"
      />
    </g>
  </svg>
</template>
