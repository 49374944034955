<script setup lang="ts">
// import
import { ref } from 'vue'
import { XIcon } from '@heroicons/vue/outline'
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { VButton, VModal } from '@/components'
import useSidebar from '@/composeables/use-sidebar'
import { useAuthStore } from '@/modules/auth/stores'
import { useModal } from '@/composeables/use-modal'
import ChangePasswordForm from '@/modules/auth/components/ChangePasswordForm.vue'

// declare variables
const router = useRouter()
const auth = useAuthStore()
const { user } = storeToRefs(auth)
const { logout } = useAuthStore()
const { isSidebarFolded } = useSidebar()
const showLogoutModal = ref(false)
const changePasswordModal = ref()
const {
  open: confirmChangePassordMe,
  handleClose: handleModalChangePasswordMeClose,
  handleOpen: handleModalChangePasswordMeOpen
} = useModal()
const {
  open: confirmLogout,
  handleClose: handleModalLogoutClose,
  handleOpen: handleModalLogoutOpen
} = useModal()

// function
function logOut() {
  logout().then(() => {
    showLogoutModal.value = false
    router.push({ name: 'login' })
  })
}
</script>

<template>
  <nav
    class="fixed top-0 z-30 h-14 w-full border-b border-bs-line bg-white px-4 py-1"
  >
    <div
      :class="isSidebarFolded ? 'ml-28' : 'ml-64'"
      class="flex items-center justify-between gap-8"
    >
      <div id="sidemenuButtonTeleport"></div>
      <div class="flex h-full items-center justify-end gap-6 pr-11">
        <Menu as="div" class="relative inline-block text-left">
          <MenuButton class="rounded-md bg-white px-4 py-1 hover:bg-gray-100">
            <div class="flex items-center justify-start gap-4">
              <template v-if="user?.image_path_uri">
                <img
                  :src="user.image_path_uri"
                  alt="user-picture"
                  class="h-8 w-8 rounded-full"
                  :class="[
                    user.image_path_uri
                      ? 'bg-transparent object-cover'
                      : 'bg-bs-red-primary-1 object-contain'
                  ]"
                />
              </template>
              <template v-else>
                <div
                  class="inline-block h-10 w-10 flex-none rounded-full object-cover"
                  :style="{
                    'background-image': `url(https://via.placeholder.com/40/FFE8E8/1F2933/?text=${
                      user && user.name?.match(/\b\w/g)?.join('')
                    })`
                  }"
                ></div>
              </template>

              <div
                v-if="user?.name && user?.type"
                class="flex flex-col items-start justify-start gap-0.5 text-bs-black-1"
              >
                <span class="truncate text-sm font-semibold capitalize">{{
                  user.name
                }}</span>
                <span class="truncate text-xs font-normal capitalize">
                  {{ user.type }}</span
                >
              </div>
            </div>
          </MenuButton>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <VButton
                    type="button"
                    class="block w-full px-4 py-2 text-left text-sm font-medium text-bs-black-1"
                    :class="[active ? 'bg-gray-100' : '']"
                    @click="handleModalChangePasswordMeOpen"
                  >
                    Ubah kata sandi
                  </VButton>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <VButton
                    type="button"
                    class="block w-full px-4 py-2 text-left text-sm font-medium text-bs-red-1"
                    :class="[active ? 'bg-gray-100' : '']"
                    @click="handleModalLogoutOpen"
                  >
                    Keluar
                  </VButton>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
  </nav>

  <!-- Logout modal -->
  <TransitionRoot as="template" :show="showLogoutModal">
    <Dialog
      as="div"
      class="fixed inset-0 z-50 overflow-y-auto"
      @close="() => (showLogoutModal = false)"
    >
      <div
        class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative my-8 inline-block w-full max-w-sm transform overflow-hidden rounded-lg bg-white p-6 align-bottom transition-all sm:align-middle"
          >
            <div class="sm:flex sm:items-start">
              <div class="w-full text-left">
                <div class="flex w-full items-center justify-between gap-8">
                  <DialogTitle
                    as="h5"
                    class="text-2xl font-semibold text-bs-black-1"
                    >Konfirmasi</DialogTitle
                  >
                  <VButton
                    type="button"
                    class="rounded-md bg-white text-bs-black-1 hover:text-bs-red-primary-1 focus:outline-none"
                    @click="() => (showLogoutModal = false)"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </VButton>
                </div>
                <div class="mt-6">
                  <p class="text-base font-normal text-bs-black-4">
                    Anda yakin ingin keluar?
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-6 flex items-center justify-between gap-4">
              <VButton
                type="button"
                class="w-full rounded-md border border-bs-red-primary-1 bg-white p-4 text-sm font-bold uppercase text-bs-red-primary-1 hover:bg-bs-red-2"
                @click="() => (showLogoutModal = false)"
              >
                Batal
              </VButton>
              <VButton
                type="button"
                class="w-full rounded-md border border-bs-red-primary-1 bg-bs-red-primary-1 p-4 text-sm font-bold uppercase text-white hover:border-red-800 hover:bg-red-800"
                @click="logOut"
              >
                Ya, keluar
              </VButton>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Modal change password -->
  <VModal
    title="Ubah kata sandi"
    align-modal="items-center"
    :open="confirmChangePassordMe"
    hide-primary-button
    hide-secondary-button
    hide-buttons
    size="sm"
    @close="handleModalChangePasswordMeClose"
  >
    <ChangePasswordForm
      ref="changePasswordModal"
      @close-form="handleModalChangePasswordMeClose"
    />
  </VModal>
  <!-- End modal change password -->

  <!-- Modal logout -->
  <VModal
    title="Konfirmasi"
    :open="confirmLogout"
    :handle-cancel="handleModalLogoutClose"
    :handle-confirm="logOut"
    custom-class-primary-button="w-full bg-bs-red-primary-1 text-bs-white-1 hover:bg-red-800"
    custom-class-secondary-button="w-full hover:text-bs-red-primary-1 hover:bg-bs-white-1"
    primary-button="Ya, Keluar"
    secondary-button="Batal"
    size="sm"
    @close="handleModalLogoutClose"
  >
    <label class="text-base font-normal text-bs-black-4">
      Anda yakin keluar dari dasbor?
    </label>
  </VModal>
  <!-- End modal logout -->
</template>
