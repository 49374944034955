<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(defineProps<Props>(), {
  as: 'button'
})

export interface Props {
  customClass?: string
  outline?: boolean
  as?: string | object
  disabled?: boolean
}

const styleMap = computed(() => {
  return props.outline
    ? props.customClass
      ? props.customClass
      : 'border block border-bs-red-primary-1 text-bs-black-1 hover:bg-bs-red-2 hover:text-bs-black-1'
    : 'block text-bs-black-1 hover:border-red-800 hover:bg-red-2 disabled:bg-bs-line'
})
</script>

<template>
  <component
    :is="as"
    type="button"
    :disabled="disabled"
    :class="styleMap"
    class="rounded-md p-3 text-sm font-bold uppercase"
  >
    <span class="uppercase">
      <slot></slot>
    </span>
  </component>
</template>
