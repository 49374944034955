import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'dokumentasi',
    name: 'dokumentasi',
    component: () => import('../pages/DocumentationView.vue'),
    meta: {
      pageTitle: 'Dokumentasi'
    }
  }
]

export default routes
