<script setup lang="ts">
import { reactive, watch } from 'vue'
import { ArrowLeftIcon } from '@heroicons/vue/outline'
import {
  useRoute,
  RouterLink,
  RouterView,
  type RouteLocationMatched
} from 'vue-router'
import { VToast } from '@/components'
import useToast from '@/composeables/use-toast'

const route = useRoute()
const { toastSecondLayout, isSuccess, successMessage, errorMessage } =
  useToast()

const pages = reactive([
  {
    title: 'Detail Pengguna',
    routeName: 'users/detail',
    previousRouteName: 'users',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail',
    routeName: 'object-auction/detail',
    previousRouteName: 'object-auction/edit',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail',
    routeName: 'object-auction/lot/:id/detail',
    previousRouteName: 'object-auction/lot/detail',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail Pembayaran dana pemohon',
    routeName: 'object-auction/lot/detail',
    previousRouteName: 'object-auction/lot',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail Objek Lelang',
    routeName: 'object-auction/edit',
    previousRouteName: 'object-auction',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail Tagihan pemenang lelang',
    routeName: 'payment-funds/detail',
    previousRouteName: 'payment-funds',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail',
    routeName: 'payment-funds/:id/detail',
    previousRouteName: 'payment-funds/detail',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail Pembayaran Dana Pemohon',
    routeName: 'payment-funds/auction-aplicant/detail',
    previousRouteName: 'payment-funds/auction-aplicant',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail',
    routeName: 'payment-funds/auction-aplicant/:id/detail',
    previousRouteName: 'payment-funds/auction-aplicant/detail',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail Penerimaan Biaya Lelang',
    routeName: 'payment-funds/auction-admin-fees/detail',
    previousRouteName: 'payment-funds/auction-admin-fees',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail',
    routeName: 'payment-funds/auction-admin-fees/:id/detail',
    previousRouteName: 'payment-funds/auction-admin-fees/detail',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail pengembalian dana',
    routeName: 'refunds/detail',
    previousRouteName: 'refunds',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail Lelang Sedang Berlangsung',
    routeName: 'auction/ongoing/detail',
    previousRouteName: 'auction/ongoing',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail Lelang Selesai',
    routeName: 'auction/done/detail',
    previousRouteName: 'auction/done',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail',
    routeName: 'auction/ongoing/:id/detail',
    previousRouteName: 'auction/ongoing/detail',
    actionButton: true,
    current: false
  },
  {
    title: 'Detail',
    routeName: 'auction/done/:id/detail',
    previousRouteName: 'auction/done/detail',
    actionButton: true,
    current: false
  }
])

const routeWatcher = async (matches: RouteLocationMatched[]) => {
  for (let menu of pages) {
    menu.current = matches.some((route) => route.name === menu.routeName)
  }
}

watch(() => route.matched, routeWatcher)

routeWatcher(route.matched)
</script>

<template>
  <div class="h-full min-h-screen bg-bs-bg">
    <VToast
      ref="toastSecondLayout"
      :status-success="isSuccess"
      :text-response="successMessage || errorMessage"
    />
    <!-- Navbar -->
    <nav
      class="fixed top-0 z-30 h-14 w-full border-b border-bs-line bg-white px-4 py-2"
    >
      <div class="flex h-full items-center justify-between px-4">
        <template v-for="(page, index) in pages" :key="index">
          <!-- Back button -->
          <RouterLink
            v-if="page.current"
            :to="{ name: page.previousRouteName }"
            class="relative flex items-center justify-center"
          >
            <ArrowLeftIcon
              class="h-6 w-6 hover:text-bs-red-primary-1"
              aria-hidden="true"
            />
          </RouterLink>
          <!-- Title -->
          <h6 v-if="page.current" class="text-xl font-semibold text-bs-black-1">
            {{ page.title }}
          </h6>
        </template>
        <!-- Action button -->
        <div id="buttonTeleport"></div>
      </div>
    </nav>

    <div class="container mx-auto py-14 xl:w-[1128px]">
      <RouterView></RouterView>
    </div>
  </div>
</template>
