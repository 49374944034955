import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'users',
    name: 'users',
    component: () => import('../pages/UserListView.vue'),
    meta: {
      pageTitle: 'Pengguna'
    }
  },
  {
    path: 'officers',
    name: 'officers',
    component: () => import('../pages/UserListView.vue'),
    meta: {
      pageTitle: 'Pejabat Lelang'
    }
  }
]

const secondRoutes: RouteRecordRaw[] = [
  {
    path: 'users/:id/detail',
    name: 'users/detail',
    component: () => import('../pages/UserDetailView.vue')
  }
]
export { secondRoutes }
export default routes
