import { ref } from 'vue'

// global state
const toast = ref()
const toastSecondLayout = ref()
const isSuccess = ref(false)
const successMessage = ref<null | string>()
const errorMessage = ref<null | string>()

export default function useToast() {

  return {
    toast,
    toastSecondLayout,
    isSuccess,
    successMessage,
    errorMessage
  }
}
