import CryptoJS from 'crypto-js'

export default function useCrypto() {
  const encrypt = (payload: string | object | object[]) => {
    return CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      import.meta.env.VITE_SECRET_KEY
    ).toString()
  }

  const decrypt = (ciphertext: string) => {
    const bytes = CryptoJS.AES.decrypt(
      ciphertext,
      import.meta.env.VITE_SECRET_KEY
    )
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  }

  return {
    encrypt,
    decrypt
  }
}
