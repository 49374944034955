<script setup lang="ts">
import { ref, watch } from 'vue'
import { VButton } from '@/components'
import { CloseIcon } from '@/components/icons'

withDefaults(defineProps<Props>(), {
  textResponse: ''
})

export interface Props {
  textResponse: string | null
  statusSuccess: boolean
}

const toast = ref(false)

watch(toast, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      toast.value = false
    }, 5000)
  }
});

function changeStatus() {
  toast.value = !toast.value
}
defineExpose({
  changeStatus
});
</script>

<template>
  <div
    v-if="toast"
    class="fixed left-0 top-4 z-[99999] w-full px-4 md:left-1/3 md:w-max md:px-0 lg:left-[40%]"
  >
    <div
      :class="
        statusSuccess
          ? 'bg-bs-green-2 text-bs-green-3'
          : 'bg-bs-red-2 text-bs-red-1'
      "
      class="relative flex items-center justify-center gap-5 overflow-hidden rounded-lg py-5 pl-10 pr-5 text-center text-sm font-semibold"
    >
      <h2>{{ textResponse }}</h2>
      <VButton
        :class="
          statusSuccess
            ? 'hover:bg-bs-green-primary-1/25'
            : 'hover:bg-bs-red-1/25'
        "
        class="group rounded-md p-1.5"
        @click="changeStatus()"
      >
        <CloseIcon
          :class="
            statusSuccess
              ? 'fill-bs-green-3 group-hover:fill-bs-green-primary-1'
              : 'fill-red-800 group-hover:fill-bs-red-1'
          "
          class="h-4 w-4"
        />
      </VButton>
      <div
        class="progress absolute bottom-0 h-[3px] w-full"
        :class="statusSuccess ? 'success' : 'failed'"
      ></div>
    </div>
  </div>
</template>

<style scoped>
.progress:before {
  animation: progress 5s linear forwards;
}

@keyframes progress {
  100% {
    right: 105%;
  }
}

.progress.failed:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #ff4b4b;
}
.progress.success:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #08a78b;
}
</style>
