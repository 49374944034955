import { $http } from '.'
import type { Password } from '@/types/profile'

function changePassword(request: Password) {
  return $http.post('/v1/password/change', request)
}

function showProfileCompany() {
  return $http.get('/v1/company')
}

function storeUpdateCompany(payload: any = {}) {
  return $http.post('/v1/company', payload)
}

export { showProfileCompany, storeUpdateCompany }
export default changePassword
