import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'dashboard',
    name: 'dashboard',
    component: () => import('../pages/DashboardView.vue'),
    meta: {
      pageTitle: 'Dashboard'
    }
  }
]

export default routes
