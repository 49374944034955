<script setup lang="ts">
import { XCircleIconFill, CloseIcon } from '@/components/icons'

defineProps<{
  additionalClass?: string
}>()

const emit = defineEmits<{
  close: [value: boolean]
}>()

function closeButton() {
  emit('close', false)
}
</script>

<template>
  <div
    class="align-items my-1 flex w-full justify-evenly rounded-md bg-red-50 p-4"
    :class="additionalClass"
  >
    <XCircleIconFill class="mr-2 h-5 w-5 fill-red-400" />
    <span class="text-sm font-medium text-red-800">
      <slot></slot>
    </span>
    <div class="ml-auto pl-3">
      <div class="-mx-1.5 -my-1.5">
        <button
          type="button"
          class="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
          @click="closeButton()"
        >
          <span class="sr-only">Dismiss</span>
          <CloseIcon class="h-5 w-5 fill-red-400" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>
