<script setup lang="ts">
import { ref, type ComponentPublicInstance } from 'vue'
import {
  Dialog,
  DialogDescription,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/solid'
import { VButton } from '@/components'

const props = withDefaults(defineProps<Props>(), {
  title: '',
  primaryButton: 'Konfirmasi',
  secondaryButton: 'Kembali',
  size: 'md',
  alignModal: 'items-end',
  customClassPrimaryButton: '',
  customClassSecondaryButton: '',
  handleConfirm: undefined,
  handleCancel: undefined
})

const emit = defineEmits<{
  close: any
}>()
const buttonConfirmRef = ref<ComponentPublicInstance<HTMLInputElement>>()
const buttonCancelRef = ref<ComponentPublicInstance<HTMLInputElement>>()
const openWithDefineExpose = ref(false)

export interface Props {
  customClassPrimaryButton?: string
  customClassSecondaryButton?: string
  open: boolean
  title?: string
  primaryButton?: string
  secondaryButton?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  alignModal?:
    | 'items-start'
    | 'items-center'
    | 'items-end'
    | 'items-baseline'
    | 'items-stretch'
  hideButtons?: boolean
  hidePrimaryButton?: boolean
  hideSecondaryButton?: boolean
  disabledButton?: boolean
  handleConfirm?: (
    e: Event | undefined
  ) => void | Promise<void> | Promise<Promise<void> | undefined>
  handleCancel?: (e: Event) => void
}

const SIZE_MAPS: Record<string, string> = {
  ['xs']: 'max-w-[348px]',
  ['sm']: 'max-w-[408px]',
  ['md']: 'max-w-2xl',
  ['lg']: 'max-w-[950px]',
  ['xl']: 'w-full'
}

function _handleConfirm(e: Event) {
  if (typeof props.handleConfirm !== 'undefined') {
    props.handleConfirm(e)
  }
}

function _handleCancel(e: Event) {
  if (typeof props.handleCancel !== 'undefined') {
    props.handleCancel(e)
  } else {
    emit('close')
  }
}
function changeToOpen() {
  openWithDefineExpose.value = true
}
function changeToClose() {
  openWithDefineExpose.value = false
}
function beforeEnter() {
  if (!buttonConfirmRef.value || !buttonCancelRef.value) return
  buttonCancelRef.value.$el.focus()
}
function afterLeave() {
  if (!buttonConfirmRef.value || !buttonCancelRef.value) return
  buttonCancelRef.value.$el.blur()
}
defineExpose({
  changeToOpen,
  changeToClose
});
</script>

<template>
  <div>
    <TransitionRoot as="template" :show="open || openWithDefineExpose">
      <Dialog as="div" class="fixed inset-0 z-50 overflow-y-auto">
        <div
          class="flex min-h-screen justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
          :class="alignModal"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            @before-enter="beforeEnter"
            @after-leave="afterLeave"
          >
            <div
              class="inline-block transform rounded-md bg-white px-6 pt-8 pb-6 text-left align-bottom transition-all sm:my-8 sm:w-full sm:p-6 sm:align-middle"
              :class="[SIZE_MAPS[size]]"
            >
              <div class="flex text-sm text-neutral-700">
                <div class="w-full break-words">
                  <div
                    v-if="$slots.title || title !== ''"
                    class="mb-4 flex w-full items-center justify-between"
                  >
                    <DialogTitle as="h3" class="text-lg font-bold leading-6">
                      <slot v-if="$slots.title" name="title"></slot>
                      <template v-else>{{ title }}</template>
                    </DialogTitle>
                    <button
                      type="button"
                      class="rounded-md bg-white text-bs-black-1 hover:text-bs-red-primary-1 focus:outline-none"
                      @click.prevent="(e: Event) => _handleCancel(e)"
                    >
                      <span class="sr-only">Batal</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                    <div v-if="$slots.action">
                      <slot name="action"></slot>
                    </div>
                  </div>

                  <slot></slot>
                </div>
              </div>

              <DialogDescription
                v-if="!hideButtons"
                as="div"
                class="mt-5 flex justify-end gap-x-3"
              >
                <VButton
                  v-if="!hideSecondaryButton"
                  ref="buttonCancelRef"
                  :disabled="disabledButton"
                  :class="customClassSecondaryButton"
                  @click.prevent="(e: Event) => _handleCancel(e)"
                >
                  {{ secondaryButton }}
                </VButton>

                <VButton
                  v-if="!hidePrimaryButton"
                  id="confirm-button"
                  ref="buttonConfirmRef"
                  :disabled="disabledButton"
                  :class="customClassPrimaryButton"
                  @click.prevent="(e: Event) => _handleConfirm(e)"
                >
                  {{ primaryButton }}
                </VButton>
              </DialogDescription>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
