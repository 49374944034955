import { ref } from 'vue'

export function useModal() {
  const open = ref(false)
  const body = ref()
  const modal = ref()

  const handleOpen = () => (open.value = true)
  const handleClose = () => (open.value = false)
  const setBody = (value: any) => (body.value = value)

  return {
    open,
    modal,
    body,
    setBody,
    handleOpen,
    handleClose
  }
}
