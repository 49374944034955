import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'employee',
    name: 'employee',
    component: () => import('../pages/EmployeView.vue'),
    meta: {
      pageTitle: 'Pegawai'
    }
  }
]

export default routes
