import { defineStore } from 'pinia'
import { $http } from '@/api'
import useCrypto from '@/composeables/use-crypto'
import { submitLogin, getProfile, submitLogout } from '@/api/auth'
import type { LoginRequest, LoginResponse } from '@/types/auth'

export const USER_LOCAL_STORAGE_KEY = 'u'
export const TOKEN_LOCAL_STORAGE_KEY = 't'
const { encrypt, decrypt } = useCrypto()

interface UserInfo {
  uuid: string
  name: string
  email: string
  is_active: boolean
  verified_status: string
  type: string
  phone_number: string
  address: string
  company_name: null | string
  image_path_uri: null | string
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: (localStorage.getItem(USER_LOCAL_STORAGE_KEY)
      ? decrypt(localStorage.getItem(USER_LOCAL_STORAGE_KEY) as string)
      : null) as UserInfo | null,
    token: (localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)
      ? decrypt(localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY) as string)
      : null) as string | null,
    isLoading: false
  }),
  // getters: {
  //   getUserRole: (state) => state.user?.role
  // },
  actions: {
    async updateAuth() {
      const response = await getProfile()
      const { data: profileResponse } = response.data

      this.user = profileResponse as UserInfo
      localStorage.setItem(USER_LOCAL_STORAGE_KEY, encrypt(profileResponse))
      return profileResponse
    },
    async login(payload: LoginRequest): Promise<LoginResponse | undefined> {
      const { email, password } = payload
      this.changeIsLoading()
      const resp = await submitLogin({
        email,
        password
      })

      if (!resp) {
        return
      }
      const { data: loginResponse } = resp.data

      this.token = loginResponse.access_token
      localStorage.setItem(
        TOKEN_LOCAL_STORAGE_KEY,
        encrypt(loginResponse.access_token)
      )

      $http.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${loginResponse.access_token}`

      const profileResponse = await this.updateAuth()

      this.user = profileResponse
      return loginResponse
    },
    async logout() {
      // await submitLogout()
      this.resetLocalStorage()
    },
    resetLocalStorage() {
      this.user = null
      localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
      this.token = null
      localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY)
    },
    changeIsLoading(){
      this.isLoading = !this.isLoading
    }
  }
})
