<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="120 120 270 270"
  >
    <g data-name="Dollar Currency">
      <path
        d="M255.996 120.712A135.288 135.288 0 1 0 391.288 256a135.288 135.288 0 0 0-135.292-135.288zm0 252.557a117.273 117.273 0 1 1 117.277-117.273A117.404 117.404 0 0 1 255.996 373.27z"
      />
      <path
        d="M255.996 158.677A97.323 97.323 0 1 0 353.323 256a97.43 97.43 0 0 0-97.327-97.323zm13.597 151.228v14.686h-21.806v-13.978c-18.398-2.058-31.906-11.152-38.135-25.73l-.654-1.532 20.242-8.582.645 1.536c3.421 8.133 15.209 14.264 27.421 14.264 5.513 0 23.508-1.024 23.508-14.197 0-6.949-7.67-11.092-24.14-13.048-17.994-2.008-42.653-4.764-42.653-31.77 0-16.664 12.589-28.286 33.763-31.285v-12.86h21.805v12.904c9.769 1.665 22.853 5.815 30.088 20.621l.748 1.526-18.6 8.612-.772-1.273c-3.474-5.75-13.655-10.258-23.178-10.258-6.454 0-21.47 1.174-21.47 12.014 0 7.88 9.643 9.652 22.255 11.154 19.165 2.361 45.406 5.593 45.406 33.66.003 20.834-17.06 31.73-34.473 33.536z"
      />
    </g>
  </svg>
</template>
