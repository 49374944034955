import axios from 'axios'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import router from '@/router'
import { useAuthStore } from '@/modules/auth/stores'

const genericSuccessResponseInterceptor = function (response: AxiosResponse) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}
const genericErrorResponseInterceptor = function (error: any) {
  const { resetLocalStorage } = useAuthStore()
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status === 401) {
      resetLocalStorage()
      router.push({ name: 'login' })
      return Promise.reject(
        new Error('Anda belum terautentikasi, harap login terlebih dahulu')
      )
    }

    return Promise.reject(error)
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return Promise.reject(
      new Error('Mohon cek koneksi internet anda dan coba lagi')
    )
  } else {
    // Error comes from AbortController signal abort
    if (error.message === 'canceled') {
      return Promise.reject(new Error('request canceled'))
    }
    // Something happened in setting up the request that triggered an Error
    return Promise.reject(error.message)
  }
}

const $http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 15000
})

const $httpNotInternal = axios.create({
  baseURL: import.meta.env.VITE_API_NON_INTERNAL_URL,
  timeout: 15000
})

$http.interceptors.request.use(function (config: AxiosRequestConfig) {
  const auth = useAuthStore()

  if (auth.token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${auth.token}`
    }
  }

  return config
})

$http.interceptors.response.use(
  genericSuccessResponseInterceptor,
  genericErrorResponseInterceptor
)

$httpNotInternal.interceptors.request.use(function (
  config: AxiosRequestConfig
) {
  const auth = useAuthStore()

  if (auth.token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${auth.token}`
    }
  }

  return config
})

$httpNotInternal.interceptors.response.use(
  genericSuccessResponseInterceptor,
  genericErrorResponseInterceptor
)

function category() {
  return $httpNotInternal.get('/v1/category')
}

export { $http, $httpNotInternal, category }
export default $http
