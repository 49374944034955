import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'object-auction',
    name: 'object-auction',
    component: () => import('../pages/ObjectAuctionView.vue'),
    meta: {
      pageTitle: 'Objek Lelang'
    }
  },
  {
    path: 'object-auction/lot',
    name: 'object-auction/lot',
    component: () => import('../pages/ObjectAuctionView.vue'),
    meta: {
      pageTitle: 'Objek Lelang'
    }
  }
]

const secondRoutesObjek: RouteRecordRaw[] = [
  {
    path: 'object-auction/:id/edit',
    name: 'object-auction/edit',
    component: () => import('../pages/ObjectAuctionEditView.vue')
  },
  {
    path: 'object-auction/lot/:id/detail',
    name: 'object-auction/lot/detail',
    component: () => import('../pages/LotObjectDetailView.vue')
  },
  {
    path: 'object-auction/:id/:index/detail',
    name: 'object-auction/detail',
    component: () => import('../pages/ObjectAuctionDetailView.vue')
  },
  {
    path: 'object-auction/lot/:id/:index/detail',
    name: 'object-auction/lot/:id/detail',
    component: () => import('../pages/LotObjectItemDetailView.vue')
  }
]
export { secondRoutesObjek }
export default routes
