/* eslint-disable import/no-named-as-default */
import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import HelloWorld from '@/components/HelloWorld.vue'
import BaseLayout from '@/layouts/BaseLayout.vue'
import SecondLayout from '@/layouts/SecondLayout.vue'

import { authRoutes } from '@/modules/auth/router'
import { dashboardRoutes } from '@/modules/dashboard/router'
import { reportRoutes } from '@/modules/report/router'
import { documentationRoutes } from '@/modules/documentation/router'
import { profileRoutes } from '@/modules/profile/router'
import { employeeRoutes } from '@/modules/employee/router'
import { usersRoutes, secondRoutes } from '@/modules/users/router'
import {
  objectAuctionRoutes,
  secondRoutesObjek
} from '@/modules/objectAuction/router'
import { auctionRoutes, secondRoutesAuction } from '@/modules/auction/router'
import {
  paymentFundsRoutes,
  secondRoutesPaymentFund
} from '@/modules/paymentFunds/router'
import { refundsRoutes, secondRoutesRefund } from '@/modules/refunds/router'

import { useAuthStore } from '@/modules/auth/stores'

NProgress.configure({ showSpinner: false })
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...authRoutes,

    // Main Menu
    {
      path: '/',
      meta: { requiresAuth: true },
      component: BaseLayout,
      redirect: { name: 'dashboard' },
      children: [
        ...dashboardRoutes,
        ...reportRoutes,
        ...documentationRoutes,
        ...profileRoutes,
        ...employeeRoutes,
        ...usersRoutes,
        ...objectAuctionRoutes,
        ...auctionRoutes,
        ...paymentFundsRoutes,
        ...refundsRoutes,
        {
          path: '/:catchAll(.*)',
          name: 'not-found',
          redirect: { name: 'dashboard' }
        }
      ]
    },

    // Second Layout Menu
    {
      path: '/',
      component: SecondLayout,
      children: [
        ...secondRoutes,
        ...secondRoutesObjek,
        ...secondRoutesAuction,
        ...secondRoutesPaymentFund,
        ...secondRoutesRefund
      ]
    },

    // {
    //   path: '/:catchAll(.*)',
    //   name: 'not-found',
    //   component: HelloWorld
    // },
    {
      path: '/404/:resource',
      name: '404-resource',
      component: HelloWorld,
      props: true
    },
    {
      path: '/403',
      name: 'forbidden',
      component: HelloWorld,
      props: true
    }
  ],
  scrollBehavior(to) {
    if (to.matched.some((route) => route.meta?.scrollToTop === false)) {
      return {}
    }
    // always scroll to top
    return {
      top: 0,
      behavior: 'smooth'
    }
  }
})

router.beforeEach(async (to) => {
  if (to.meta.pageTitle) document.title = `${to.meta.pageTitle} | GoAuction`
  const { user, updateAuth } = useAuthStore()
  if (user) {
    await updateAuth()
  }

  if (to.meta.requiresAuth && !user) return '/login'
  if (to.meta.guestOnly && user) return '/'
})

router.afterEach(() => {
  NProgress.done()
})

export default router
