import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'report',
    name: 'report',
    component: () => import('../pages/ReportView.vue'),
    meta: {
      pageTitle: 'Laporan'
    }
  }
]

export default routes
