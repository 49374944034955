<script setup lang="ts">
import { RouterView } from 'vue-router'
import useSidebar from '@/composeables/use-sidebar'
import Navbar from '@/components/NavbarMenu.vue'
import Sidebar from '@/components/SidebarMenu.vue'
import { VToast } from '@/components'
import useToast from '@/composeables/use-toast'

const { toast, isSuccess, successMessage, errorMessage } = useToast()
const { isSidebarFolded } = useSidebar()
</script>

<template>
  <div class="h-full min-h-screen bg-bs-bg">
    <VToast
      ref="toast"
      :status-success="isSuccess"
      :text-response="successMessage || errorMessage"
    />
    <Navbar />
    <div class="flex items-start justify-start">
      <Sidebar />
      <div
        :class="isSidebarFolded ? 'ml-24' : 'ml-60'"
        class="relative min-h-screen w-full bg-bs-bg pt-14"
      >
        <div class="p-9 pb-40">
          <RouterView></RouterView>
        </div>
        <div
          class="absolute bottom-0 flex h-14 w-full items-center justify-end border-t border-bs-line py-2 px-[60px]"
        >
          <p class="text-right text-xs text-bs-black-3">
            © Copyright 2023 GoAuction | Version 1.0
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
