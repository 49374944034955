<script setup lang="ts">
import * as zod from 'zod'
import { ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import { toFormValidator } from '@vee-validate/zod'
import type { AxiosError } from 'axios'
import changePassword from '@/api/profile'
import type { GenericErrorResponse } from '@/types'
import useToast from '@/composeables/use-toast'
import { VButton, VError, VLoading } from '@/components'

interface PasswordForm {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

const emit = defineEmits<{
  closeForm: any
}>()

const {
  toast,
  isSuccess,
  successMessage,
  errorMessage: messageError
} = useToast()
const isLoading = ref(false)
const isError = ref(false)
const errorMessageResponse = ref<null | string>()

const initialFormPassword = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: ''
}

const { handleReset } = useForm<PasswordForm>({
  initialValues: initialFormPassword,
  validationSchema: toFormValidator(
    zod
      .object({
        oldPassword: zod.string().min(1, 'Kata Sandi Lama Harus Di isi'),
        newPassword: zod
          .string()
          .min(1, 'Kata Sandi Baru Harus Di isi')
          .refine(
            (value) => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/.test(value),
            'Password Setidaknya memiliki satu huruf besar, satu huruf kecil dan satu angka'
          ),
        confirmNewPassword: zod
          .string()
          .min(1, 'Konfirmasi Kata Sandi Baru Anda')
      })
      .superRefine(({ confirmNewPassword, newPassword, oldPassword }, ctx) => {
        if (newPassword === oldPassword) {
          ctx.addIssue({
            code: 'custom',
            message: 'Kata Sandi Baru Sama Dengan Kata Sandi lama',
            path: ['newPassword']
          })
        }
        if (newPassword !== confirmNewPassword) {
          ctx.addIssue({
            code: 'custom',
            message: 'Kata Sandi Baru Tidak Sesuai',
            path: ['confirmNewPassword']
          })
        }
      })
  )
})

const { value: oldPassword, errorMessage } = useField<string>('oldPassword')
const { value: newPassword, errorMessage: errorMessageNewPassword } =
  useField<string>('newPassword')
const { value: confirmNewPassword, errorMessage: errorMessageConfirm } =
  useField<string>('confirmNewPassword')

async function store() {
  try {
    isError.value = false
    isLoading.value = true

    await changePassword({
      password: newPassword.value,
      current_password: oldPassword.value
    })
    // toast
    toast.value.changeStatus()
    isSuccess.value = true
    successMessage.value = 'Password Berhasil Diubah !!'

    isLoading.value = false

    handleReset()
    emit('closeForm')
  } catch (error) {
    isError.value = true
    isLoading.value = false

    // toast
    isSuccess.value = false
    messageError.value = (
      error as AxiosError<GenericErrorResponse>
    ).response?.data.message

    errorMessageResponse.value = (
      error as AxiosError<GenericErrorResponse>
    ).response?.data.message
  }
}
</script>

<template>
  <div>
    <label class="text-base font-normal text-bs-black-4">
      Silahkan ubah kata sandi dengan yang baru
    </label>

    <VError v-if="isError" @close="(value) => (isError = value)">{{
      errorMessage
    }}</VError>

    <div class="form-input mb-4 border-0 p-0">
      <label
        for="oldPassword"
        class="mt-5 block text-sm font-semibold text-bs-black-1"
        >Kata sandi lama</label
      >
      <div class="mt-2">
        <input
          id="oldPassword"
          v-model="oldPassword"
          type="password"
          autocomplete="current-password"
          placeholder="*********"
          required
          class="block w-full appearance-none rounded-md border border-bs-line bg-bs-bg px-4 py-3 text-sm font-medium text-bs-black-1 placeholder-bs-black-3 placeholder:font-normal focus:border-bs-red-primary-1 focus:outline-none focus:ring-bs-red-primary-1"
        />
        <span v-if="errorMessage" class="text-bs-red-primary-1">
          {{ errorMessage }}
        </span>
      </div>
    </div>
    <div class="form-input mb-4 border-0 p-0">
      <label
        for="newPassword"
        class="mt-5 block text-sm font-semibold text-bs-black-1"
        >Kata sandi baru</label
      >
      <div class="mt-2">
        <input
          id="newPassword"
          v-model="newPassword"
          type="password"
          placeholder="*********"
          required
          class="block w-full appearance-none rounded-md border border-bs-line bg-bs-bg px-4 py-3 text-sm font-medium text-bs-black-1 placeholder-bs-black-3 placeholder:font-normal focus:border-bs-red-primary-1 focus:outline-none focus:ring-bs-red-primary-1"
        />
        <span v-if="errorMessageNewPassword" class="text-bs-red-primary-1">
          {{ errorMessageNewPassword }}
        </span>
      </div>
    </div>
    <div class="form-input mb-4 border-0 p-0">
      <label
        for="newPasswordConfirmation"
        class="mt-5 block text-sm font-semibold text-bs-black-1"
        >Konfirmasi kata sandi baru</label
      >
      <div class="mt-2">
        <input
          id="newPasswordConfirmation"
          v-model="confirmNewPassword"
          type="password"
          autocomplete="current-password"
          placeholder="*********"
          required
          class="block w-full appearance-none rounded-md border border-bs-line bg-bs-bg px-4 py-3 text-sm font-medium text-bs-black-1 placeholder-bs-black-3 placeholder:font-normal focus:border-bs-red-primary-1 focus:outline-none focus:ring-bs-red-primary-1"
        />
        <span v-if="errorMessageConfirm" class="text-bs-red-primary-1">
          {{ errorMessageConfirm }}
        </span>
      </div>
    </div>
    <div class="mt-6 flex gap-4">
      <VButton
        class="w-full text-bs-red-primary-1"
        @click.prevent="() => emit('closeForm')"
      >
        Nanti
      </VButton>
      <VButton
        :disabled="
          oldPassword === '' ||
          errorMessage !== undefined ||
          newPassword === '' ||
          errorMessageNewPassword !== undefined ||
          confirmNewPassword === '' ||
          errorMessageConfirm !== undefined
        "
        :class="
          oldPassword === '' ||
          errorMessage !== undefined ||
          newPassword === '' ||
          errorMessageNewPassword !== undefined ||
          confirmNewPassword === '' ||
          errorMessageConfirm !== undefined
            ? 'cursor-not-allowed bg-[#cccccc] text-[#666666]'
            : 'bg-bs-red-primary-1 text-bs-white-1 hover:bg-red-800'
        "
        class="w-full"
        @click.prevent="store"
      >
        <span v-if="isLoading">
          <VLoading
            color="#D50422"
            border-color="rgb(14 44 71 / 20%)"
            size="24px"
          />
        </span>
        <span v-else> Simpan </span>
      </VButton>
    </div>
  </div>
</template>
